<template>
    <section class="view blog">
        <view-title v-bind:title="$t('message.blog.title')"></view-title>
        <div class="container py-5">
            <h2 class="text-center mb-5"
                v-html="title">
            </h2>

            <div class="row mb-5">
                <div class="col-md-6 mx-auto position-relative">
                    <img class="img-fluid"
                         v-if="article"
                         v-bind:alt="title"
                         v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/mall/media/${ article.titleImage }`">
                </div>
            </div>

            <div class="content mb-3"
                 v-html="content">
            </div>

            <div class="d-flex align-items-baseline justify-content-center justify-content-md-start">
                <div class="d-none d-sm-inline text-dark mr-sm-2">
                    {{ $t("message.products.share-to") }}
                </div>

                <share-to-s-n-s v-bind:networks="$config.SupportedSNS"
                                v-bind:url="`${ $config.baseUrl }${ $route.path }`"
                                v-bind:title="`${ $sanitize(title) }`"
                                v-bind:description="`${ $sanitize(content) }`"
                                v-bind:quote="`${ $sanitize(title) } | marier Skincare 曼肌兒`"
                                v-bind:hashtags="`marier,skincare,${ $sanitize(title) }`">
                </share-to-s-n-s>
            </div>
        </div>
    </section>
</template>

<script>
import getBlogById from "../api/mall/getBlogById";

import ViewTitle from "../components/ViewTitle";
import ShareToSNS from "../components/ShareToSNS";

export default {
    name: "BlogDetails",
    components: {
        ShareToSNS,
        ViewTitle
    },
    metaInfo () {
        return {
            title: `${ this.$sanitize(this.title) }`,
            meta: [{
                "name": "description",
                "content": this.$sanitize(this.content),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": `${ this.$sanitize(this.title) }`,
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$sanitize(this.content),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "product.item",
                "vmid": "og:type"
            }, {
                "property": "og:image",
                "content": this.article ? `${ this.$config.apiUrl }/${ this.$config.apiVersion }/mall/media/${ this.article.titleImage }` : "",
                "vmid": "og:image"
            }]
        }
    },
    props: {
        "id": {
            type: String,
        },
    },
    data () {
        return {
            "article": null,
        }
    },
    computed: {
        title () {
            return this.article?.title?.[this.$i18n.locale] ?? "";
        },

        content () {
            return this.article?.content?.[this.$i18n.locale] ?? "";
        },
    },
    async beforeMount () {
        this.article = this.$store.state.mall
            .blog
            .find(
                ({ _id }) => _id === this.id
            );

        if (!this.article) {
            try {
                this.article = await getBlogById(this.id);
            } catch (e) {
                return;
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
.view.blog {
    .content {
        img {
            max-width: 750px;
            width: 100%;
            height: auto;
            margin: 1rem auto;
            display: block;
        }
    }
}
</style>

