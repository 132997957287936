import { apiUrl, apiVersion, } from "../../config";
import localForage from "localforage";

export async function getBlogById (
    blogId,
) {
    const lang = await localForage.getItem("marier.user-lang");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/mall/blog/${ blogId }`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Accept-Language": lang,
            },
        }
    );

    if (response.ok) {
        const blog = await response.json();
        if (blog) {
            return blog;
        }
        throw "BLOG GET BY ID error";
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "Internal server error";
}

export default getBlogById;
